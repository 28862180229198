import Editor, {Monaco, OnChange, useMonaco} from "@monaco-editor/react";
import {editor, IDisposable, KeyCode, KeyMod} from "monaco-editor";
import {useContext, useEffect} from "react";
import {EditorContext} from "./Context/EditorContext";
import {MQTTContext} from "../App";
import IStandaloneCodeEditor = editor.IStandaloneCodeEditor;
import {TypedArray} from "paho-mqtt";


const stopScript = `
for k, v in pairs(_G) do
    if type(v) == "table" then
        if v._type == "script" then
            v._stop = true
        end
        if v._type == "layer" then
            v._stack = {}
            v._effects = {}
        end
    end
end
`

const defaultScript = `-- LMixer UI revamped [ALPHA]
-- Select a script in the menu

-- Hotkeys:
--
-- Ctrl+Shift+Enter:       Run script
-- Ctrl+Enter:             Save and run script
-- Ctrl+S:                 Save script
-- Ctrl+P:                 Stop script
-- Ctrl+Shift+Backspace:   Stop all scripts
-- Ctrl+M:                 New script

-- Some quality of life functions:

-- * Syntax errors are now displayed as a notification in the down right corner when you save a script.
-- * Code editor is upgraded to the Monaco Editor - same as Visual Studio code.
-- * You can restart lmixer from the actions menu.
-- * Ability to "move" or rename scripts
-- * Search / Filter for scripts in the scripts menu
-- * Scripts menu is now sorted alphabetically

-- Other changes worth mentioning:
-- * Scripts can no longer be deleted by saving an empty script; use the delete button in the menu instead.

-- WIP:

-- * Folders for scripts
`

type CodeEditorProps = {
    onNewScript: () => void;
}

export const CodeEditor = ({onNewScript}: CodeEditorProps) => {
    const {editorData, setEditorData} = useContext(EditorContext);
    const {pub} = useContext(MQTTContext);
    useEffect(() => {
        console.log('pub changed');
    }, [pub]);

    const monaco = useMonaco();
    useEffect(() => {
        if (!monaco) {
            return
        }
        const disposables: IDisposable[] = [];
        disposables.push(monaco.editor.addEditorAction({
            id: 'run',
            label: 'Run',
            keybindings: [KeyMod.CtrlCmd | KeyMod.Shift | KeyCode.Enter],
            run(editor: editor.ICodeEditor): void | Promise<void> {
                console.log('RUN');
                pub('light_mixer/code/run', `${editorData.name}:start()`, 0, false);
            }
        }));
        disposables.push(monaco.editor.addEditorAction({
            id: 'save',
            label: "Save",
            keybindings: [KeyMod.CtrlCmd | KeyCode.KeyS],
            run: editor => {
                if (editorData.name === '') {
                    // TODO: Show banner, nothing to save
                } else if (editorData.name === 'fixtures') {
                    pub("light_mixer/fixtures", editorData.content, 0, true);
                } else if (editorData.name === 'layers') {
                    pub("light_mixer/layers", editorData.content, 0, true);
                } else if (editorData.name === 'code') {
                    pub("light_mixer/extra", editorData.content, 0, true);
                } else {
                    const content : string | TypedArray = editorData.content === '' ? new Uint8Array(0) : editorData.content;
                    pub(`light_mixer/code/scripts/${editorData.name}`,
                        content, 0, true);
                }
            }
        }));
        disposables.push(monaco.editor.addEditorAction({
            id: 'stop',
            label: 'Stop script',
            keybindings: [KeyMod.CtrlCmd | KeyCode.KeyP],
            run: editor => {
                console.log('Stop');
                pub('light_mixer/code/run', `${editorData.name}:stop()`, 0, false);
            }
        }));

        disposables.push(monaco.editor.addEditorAction({
            id: 'save-run',
            label: 'Save and run',
            keybindings: [KeyMod.CtrlCmd  | KeyCode.Enter],
            run: editor => {
                console.log('Save!');
                console.log('Save', editorData);
                if (editorData.name === '') {
                    // TODO: Show banner, nothing to save
                } else if (editorData.name === 'fixtures') {
                    pub("light_mixer/fixtures", editorData.content, 0, true);
                } else if (editorData.name === 'layers') {
                    pub("light_mixer/layers", editorData.content, 0, true);
                } else if (editorData.name === 'code') {
                    pub("light_mixer/extra", editorData.content, 0, true);
                } else {
                    const content : string | TypedArray = editorData.content === '' ? new Uint8Array(0) : editorData.content;
                    pub(`light_mixer/code/scripts/${editorData.name}`,
                        content, 0, true);
                }
                pub('light_mixer/code/run', `${editorData.name}:start()`, 0, false);
            }
        }));

        disposables.push(monaco.editor.addEditorAction({
            id: 'stop-all',
            label: 'Stop all',
            keybindings: [KeyMod.CtrlCmd |  KeyMod.Shift | KeyCode.Backspace],
            run: editor => {
                console.log('Stop all');
                pub('light_mixer/code/run', stopScript, 0, false);
            }
        }));

        disposables.push(monaco.editor.addEditorAction({
            id: 'new-script',
            label: 'New script',
            keybindings: [KeyMod.CtrlCmd | KeyCode.KeyM],
            run: editor => {
                onNewScript();
            }
        }));

        return () => {
            disposables.forEach(disposable => disposable.dispose());
        }
    }, [monaco, editorData, pub, onNewScript]);

    useEffect(() => {
    }, [editorData, pub]);

    const onEditorChange: OnChange = (value, event) => {
        if (value) {
            setEditorData(prev => ({...prev, content: value}));
        }
    };

    const onMount = (editor: IStandaloneCodeEditor, monaco: Monaco) => {
        editor.focus();
    }

    return <Editor defaultLanguage="lua" defaultValue={defaultScript} theme={'vs-dark'}
                   wrapperProps={{className: 'flex-grow-1'}}
                   value={editorData?.content} onChange={onEditorChange} onMount={onMount}
                   options={{tabCompletion: "on"}}
    />
}
