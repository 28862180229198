import Header from "./Components/Header";
import {createContext, useCallback, useEffect, useState} from "react";
import {SimpleMQTT, useSimpleMQTT} from "./Components/MQTT/useSimpleMQTT";
import {EditorContext} from "./Components/Context/EditorContext";
import {DefaultLMixerData, LMixerContext, LMixerData} from "./Components/Context/LMixerContext";
import {LMixerScript} from "./types";
import {Toast, ToastContainer} from "react-bootstrap";
import {CodeEditor} from "./Components/CodeEditor";
import {Qos} from "paho-mqtt";
import {ScriptNameModal} from "./Components/Modal/ScriptNameModal";

export const MQTTContext = createContext<SimpleMQTT>({} as SimpleMQTT);

const App = () => {
    const mqtt = useSimpleMQTT();
   // const mqtt = useSimpleMQTT('ws://localhost:8080/mqtt');
    const {bind, setSubs, error} = mqtt;
    const [lMixerData, setLMixerData] = useState<LMixerData>(DefaultLMixerData);
    const [editorData, setEditorData] = useState<LMixerScript>({
        content: "", folder: "", name: ""
    });

    const [showToast, setShowToast] = useState(false);
    const [toastText, setToastText] = useState('');

    const [showNewScript, setShowNewScript] = useState(false);
    const [showMoveScript, setShowMoveScript] = useState(false);

    const {pub} = mqtt;

    useEffect(() => {
        console.log(error);
    }, [error]);

    useEffect(() => {
        console.log('rerender mqtt');
        const onScript = (topic: string, message: string | null, qos: Qos, retain: boolean) => {
            const name = topic.split('/').slice(-1)
            let folder = topic.split('/').slice(3, -1).join('/')
            if (folder === '') {
                folder = `Other`
            }
            const ent: LMixerScript = {content: message ?? '', folder: folder, name: name[0]}
            setLMixerData(prev => {
                if (message === null || message?.length === 0) {
                    console.log('deleting', name[0])
                    return {...prev, scripts: prev.scripts.filter((x => x.name !== name[0]))}
                } else {
                    const oldScript = prev.scripts.find((x) => x.name === name[0]);
                    if (!oldScript) {
                        return {...prev, scripts: [...prev.scripts, ent]}
                    }
                    const newArray = prev.scripts.map((x) => {
                        if (x.name === name[0]) {
                            return ent
                        }
                        return x
                    });
                    return {...prev, scripts: newArray}
                }
            });
        };

        const onLayers = (topic: string, message: string | null) => {
            setLMixerData(prev => ({...prev, layers: {name: "layers", folder: "", content: message ?? ''}}))
        };
        const onFixtures = (topic: string, message: string | null) => {
            setLMixerData(prev => ({...prev, fixtures: {name: "fixtures", folder: "", content: message ?? ''}}))
        };
        const onCode = (topic: string, message: string | null) => {
            setLMixerData(prev => ({...prev, code: {name: "code", folder: "", content: message ?? ''}}))
        };

        const onMixerRestarted = () => {
            setToastText(() => 'LMixer restarted!')
            setTimeout(() => {
                setShowToast(() => false);
            }, 2000)
        }

        const onMixerRestart = () => {
            setShowToast(() => true);
            setToastText(() => 'Restarting LMixer...');
        }

        const onError = (topic: string, message: string | null) => {
            setToastText(() => message ?? 'Unknown error');
            setShowToast(() => true);
            setTimeout(() => {
                setShowToast(() => false);
            }, 2000)
        }

        bind('light_mixer/error', onError)
        bind(/light_mixer\/code\/scripts\/.*/, onScript)
        bind('light_mixer/fixtures', onFixtures)
        bind('light_mixer/layers', onLayers)
        bind('light_mixer/extra', onCode)
        bind('light_mixer/restart', onMixerRestart)
        bind('light_mixer/restarted', onMixerRestarted)
        setSubs(["light_mixer/#"]);
    }, [bind, setSubs]);


    const onNewScriptSubmit = useCallback((newScriptName: string) => {
        pub(`light_mixer/code/scripts/${newScriptName}`, `-- ${newScriptName}`, 0, true);
        setEditorData(() => ({content: `-- ${newScriptName}`, folder: 'Other', name: newScriptName}));
    }, [pub])

    const onDeleteScript = useCallback(() => {
        if (editorData.name === '') {
            return;
        }
        pub(`light_mixer/code/scripts/${editorData.name}`, new Uint8Array(0), 0, true);
        setEditorData(() => ({content: `-- ${editorData.name} Deleted!`, folder: '', name: ''}));
    }, [editorData.name, pub]);

    const onMoveScript = useCallback((newName: string) => {
        pub(`light_mixer/code/scripts/${newName}`, editorData.content, 0, true);
        pub(`light_mixer/code/scripts/${editorData.name}`, new Uint8Array(0), 0, true);
        setEditorData((prev) => ({...prev, name: newName}));
    }, [editorData.content, editorData.name, pub]);


    return <MQTTContext.Provider value={mqtt}>
        <LMixerContext.Provider value={lMixerData}>
            <EditorContext.Provider value={{editorData, setEditorData}}>
                <div className={'fluid d-flex flex-column'} style={{minHeight: '100vh'}}>
                    <Header onNewScript={() => setShowNewScript(true)}
                            onDelete={() => onDeleteScript()}
                            onMove={() => setShowMoveScript(true)}
                    />
                    <CodeEditor onNewScript={() => setShowNewScript(true)}></CodeEditor>
                </div>

                <ScriptNameModal show={showNewScript} title={'New script'} setShow={setShowNewScript}
                                 onSubmit={onNewScriptSubmit}/>
                <ScriptNameModal show={showMoveScript} title={'Move script'} setShow={setShowMoveScript}
                                 onSubmit={onMoveScript}/>

                <ToastContainer position={"bottom-end"}>
                    <Toast bg={"dark"} show={showToast} animation={false}>
                        <Toast.Header>
                            LMixer
                            <div className={'me-auto'}></div>
                        </Toast.Header>
                        <Toast.Body>
                            {toastText}
                        </Toast.Body>
                    </Toast>
                </ToastContainer>
            </EditorContext.Provider>
        </LMixerContext.Provider>
    </MQTTContext.Provider>;
};

export default App;
