import {useContext, useEffect, useMemo, useState} from "react";
import {LMixerContext} from "../../Context/LMixerContext";
import {LMixerScript} from "../../../types";
import {EditorContext} from "../../Context/EditorContext";
import {NavDropdown} from "react-bootstrap";
import {useMonaco} from "@monaco-editor/react";

type ScriptMap = { [key: string]: ScriptMap | LMixerScript[] }

type ScriptListProps = {
    search: string
}

export const ScriptList = ({search}: ScriptListProps) => {
    const lMixerData = useContext(LMixerContext);
    const [menuData, setMenuData] = useState<ScriptMap>({});

    useEffect(() => {
        const newScripts: ScriptMap = {};
        lMixerData.scripts.forEach((ent) => {
            if (search !== '' && !ent.name.includes(search)) {
                return
            }
            const folders = ent.folder.split("/");
            folders.forEach((folder, index) => {
                if (newScripts[folder] === undefined) {
                    if (index < folders.length - 1) {
                        newScripts[folder] = {}
                    } else {
                        newScripts[folder] = [ent]
                    }
                } else if (Array.isArray(newScripts[folder])) {
                    const data = newScripts[folder] as LMixerScript[]
                    newScripts[folder] = [...data, ent]
                }
            });
        })
        setMenuData(newScripts);
    }, [lMixerData.scripts, search]);

    // Temporarily circumvent the folder feature.
    const otherHack = useMemo(() => {
        const list = (menuData['Other'] ?? []) as LMixerScript[]
        return list.sort((a, b) => a.name.localeCompare(b.name))
    }, [menuData])

    return <ScriptMenuEntry obj={otherHack}/>
}

const ScriptMenuEntry = ({obj}: { obj: ScriptMap | LMixerScript[] }) => {
    const {setEditorData} = useContext(EditorContext);
    const monaco = useMonaco();
    const onClick = (ent: LMixerScript) => {
        setEditorData(ent);
        monaco?.editor.getEditors()[0].focus()
    };

    if (Array.isArray(obj)) {
        return <>{obj.map((ent) => <NavDropdown.Item key={ent.name}
                                                     onClick={() => onClick(ent)}>{ent.name}</NavDropdown.Item>)}</>
    }
    return <>{
        Object.keys(obj).map(key => <NavDropdown title={key} key={key}>
            <ScriptMenuEntry obj={obj[key]}/>
        </NavDropdown>)}
    </>;
}
