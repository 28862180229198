import {useState} from 'react';
import {Container, DropdownDivider, Form, Nav, Navbar, NavDropdown} from 'react-bootstrap';
import {ActionsMenu} from "./Menu/Navigation/Actions/ActionsMenu";
import {SetupMenu} from "./Menu/Navigation/SetupMenu";
import {ScriptList} from './Menu/Navigation/ScriptList';

type HeaderProps = {
    onNewScript: () => void;
    onDelete: () => void;
    onMove: () => void;
}

const Header = ({onNewScript, onDelete, onMove}: HeaderProps) => {
    const [expanded, setExpanded] = useState(false);
    const [search, setSearch] = useState('');

    return <header>
        <Navbar expand={'lg'} expanded={expanded} className={'p-0 bg-body-tertiary'}>
            <Container fluid>
                <Navbar.Brand href={'/'}><h3>LMixer</h3></Navbar.Brand>
                <Navbar.Toggle aria-controls="navbar-nav" onClick={() => setExpanded(!expanded)}/>
                <Navbar.Collapse id={'navbar-nav'}>
                    <Nav className={'me-auto'}>
                        <SetupMenu/>
                        <ActionsMenu onNewScript={onNewScript} onDelete={onDelete} onMove={onMove}/>
                        <NavDropdown title={'Scripts'} style={{minWidth: 300}}>
                            <Form.Control value={search} onChange={(e) => setSearch(e.target.value)} type={'search'}
                                          placeholder={'Search'} aria-label={'Search'}/>
                            <DropdownDivider/>
                            <ScriptList search={search}/>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    </header>
}

export default Header;
