import {useMemo} from "react";
import {useLMixerContext} from "../Components/Context/LMixerContext";

export const useScriptNameValidator = (name: string) => {

    const lmixerContext = useLMixerContext();

    const {valid, reason} = useMemo(() => {
        let reason = 'Script names must be valid Lua identifiers';
        let valid = true;
        if (name === '') {
            valid = false;
            reason = '';
            return {valid, reason};
        }
        if (/^[0-9 -]/.test(name)) {
            valid = false;
            reason = 'Script names must not start with a number, space, or hyphen';
            return {valid, reason};
        }
        if (!/^[a-zA-Z0-9_]+$/.test(name)) {
            valid = false;
            return {valid, reason};
        }
        const script = lmixerContext.scripts.find((x) => x.name === name);
        if (script) {
            valid = false;
            reason = 'A script with this name already exists';
        }
        return {valid, reason};
    }, [name, lmixerContext.scripts]);
    return {
        valid,
        reason
    }
}
