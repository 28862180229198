import {NavDropdown} from "react-bootstrap";
import {useCallback, useContext} from "react";
import {EditorContext} from "../../Context/EditorContext";
import {LMixerContext} from "../../Context/LMixerContext";
import {useMonaco} from "@monaco-editor/react";
import {LMixerScript} from "../../../types";

export const SetupMenu = () => {
    const {setEditorData} = useContext(EditorContext);
    const {fixtures, layers, code} = useContext(LMixerContext);
    const monaco = useMonaco();

    const onSet= useCallback((fixtures: LMixerScript) => {
        setEditorData(() => fixtures);
        monaco?.editor.getEditors()[0].focus();
    }, [monaco?.editor, setEditorData])

    return <NavDropdown title={'Setup'}>
        <NavDropdown.Item onClick={() => onSet(fixtures)}>Fixtures</NavDropdown.Item>
        <NavDropdown.Item onClick={() => onSet(layers)}>Layers</NavDropdown.Item>
        <NavDropdown.Item onClick={() => onSet(code)}>Code</NavDropdown.Item>
    </NavDropdown>
}
