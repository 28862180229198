import {Button, Col, Form, FormGroup, Modal, Row} from "react-bootstrap";
import {Dispatch, KeyboardEventHandler, SetStateAction, useCallback, useState} from "react";
import {useScriptNameValidator} from "../../Hooks/useScriptNameValidator";

interface ScriptNameModalProps {
    show: boolean
    title: string
    setShow: Dispatch<SetStateAction<boolean>>
    onSubmit: (name: string) => void
}

export const ScriptNameModal = ({title, setShow, show, onSubmit}: ScriptNameModalProps) => {
    const [newScriptName, setNewScriptName] = useState('');
    const {valid, reason} = useScriptNameValidator(newScriptName);
    const submit = useCallback(() => {
        if (valid) {
            onSubmit(newScriptName);
            setShow(false);
            setNewScriptName('');
        }
    }, [newScriptName, onSubmit, setShow, valid]);

    const onScriptNameInput: KeyboardEventHandler<HTMLInputElement> = (e) => {
        if (e.key === 'Enter') {
            submit();
        }
    };

    return <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <FormGroup>
                <Form.Label>Script Name</Form.Label>
                <Form.Control type={'text'} onSubmit={submit} onKeyDown={onScriptNameInput} autoFocus={true}
                              value={newScriptName}
                              onChange={(e) => setNewScriptName(e.target.value)}/>
            </FormGroup>
        </Modal.Body>
        <Modal.Footer>
            <Form.Group as={Row}>
                {!valid && <Form.Text as={Col}>
                    {reason}
                </Form.Text>}
                <Col className={'d-flex flex-row-reverse'}>
                    <Button onClick={() => {
                        submit()
                    }} disabled={!valid} variant={'success'}>Create</Button>
                    <Button onClick={() => setShow(false)} variant={'danger'}>Cancel</Button>
                </Col>
            </Form.Group>
        </Modal.Footer>
    </Modal>
}

