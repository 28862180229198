import {DropdownDivider, NavDropdown} from "react-bootstrap";
import {RunAction} from "./RunAction";
import {StopAction} from "./StopAction";
import {NewAction} from "./NewAction";
import {SaveAction} from "./SaveAction";
import {RestartMixerAction} from "./RestartMixerAction";
import {DeleteAction} from "./DeleteAction";
import {MoveAction} from "./MoveAction";

interface ActionsMenuProps {
    onNewScript: () => void
    onMove: () => void
    onDelete: () => void
}

export const ActionsMenu = ({onNewScript, onDelete, onMove}: ActionsMenuProps) => {
    return <NavDropdown title={'Actions'}>
        <SaveAction/>
        <RunAction/>
        <StopAction/>
        <DropdownDivider/>
        <NewAction onClick={onNewScript}/>
        <MoveAction onClick={onMove}/>
        <DeleteAction onClick={onDelete}/>
        <DropdownDivider/>
        <RestartMixerAction/>
    </NavDropdown>
}
