import {NavDropdown} from "react-bootstrap";
import {useContext} from "react";
import {EditorContext} from "../../../Context/EditorContext";
import {MQTTContext} from "../../../../App";
import {useMonaco} from "@monaco-editor/react";

export const SaveAction = () => {
    const {editorData} = useContext(EditorContext);
    const {pub} = useContext(MQTTContext);
    const monaco = useMonaco();
    const handleClick = () => {
        if (editorData.name === '') {
            // TODO: Show banner, nothing to save
        } else if (editorData.name === 'fixtures') {
            pub("light_mixer/fixtures", editorData.content, 0, true);
        } else if (editorData.name === 'layers') {
            pub("light_mixer/layers", editorData.content, 0, true);
        } else if (editorData.name === 'code') {
            pub("light_mixer/extra", editorData.content, 0, true);
        } else {
            pub(`light_mixer/code/scripts/${editorData.name}`,
                editorData.content, 0, true);
        }
        monaco?.editor.getEditors()[0].focus();
        // TODO: Success banner
    };

    return <NavDropdown.Item onClick={handleClick}>Save</NavDropdown.Item>
}
