import {NavDropdown} from "react-bootstrap";
import {useContext} from "react";
import {EditorContext} from "../../../Context/EditorContext";
import {MQTTContext} from "../../../../App";
import {useMonaco} from "@monaco-editor/react";

export const StopAction = () => {
    const {editorData} = useContext(EditorContext);
    const {pub} = useContext(MQTTContext);
    const monaco = useMonaco();

    const handleClick = () => {
        pub(`light_mixer/code/stop`, `${editorData.name}:stop()`, 0, false);
        monaco?.editor.getEditors()[0].focus();
    }
    return <NavDropdown.Item onClick={handleClick}>Stop</NavDropdown.Item>
}
