import {useContext} from "react";
import {MQTTContext} from "../../../../App";
import {NavDropdown} from "react-bootstrap";
import {useMonaco} from "@monaco-editor/react";

export const RestartMixerAction = () => {
    const {pub} = useContext(MQTTContext);
    const monaco = useMonaco();

    const handleClick = () => {
        pub("light_mixer/restart", "", 0, true);
        monaco?.editor.getEditors()[0].focus();
    };

    return <NavDropdown.Item onClick={handleClick}>Restart Mixer</NavDropdown.Item>
}
