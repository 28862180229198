import {createContext, useContext} from "react";
import {LMixerScript} from "../../types";

export const DefaultLMixerData = {
    code: { content: "", folder: "", name: "" },
    fixtures: { content: "", folder: "", name: "" },
    layers: { content: "", folder: "", name: "" },
    scripts: []
}
export const LMixerContext = createContext<LMixerData>(DefaultLMixerData);

export const useLMixerContext = () => {
    return useContext(LMixerContext);
};

export type LMixerData = {
    scripts: LMixerScript[]
    layers: LMixerScript,
    code: LMixerScript
    fixtures: LMixerScript
}
